// create user related store
import { acceptHMRUpdate } from "pinia";
import type { LoginForm } from "@/composables/useURM";
import type { User } from "@/types/user";
import type { OrgProfile } from "@/types/organizations";

// Store to keep data about the user logged in (user data, organization data ...)
export const useUserStore = defineStore(
	"User",
	() => {
		const { login, logout } = useURM();
		const { userAuth } = useURMApi();
		const { viewOrganization } = useOrgMApi();
		const { getMainShippingAddressAddressbook } = useABMApi();

		// data
		const user = ref<User | null>();
		const userOrganizationProfile = ref<OrgProfile | null>(null);
		const isLoggedIn = computed(() => !!user.value);
		// async|logic
		// function for user login
		async function userLogin(data: LoginForm, sso: boolean) {
			const userInfo = await login(data, sso);
			user.value = userInfo;
			if (user.value.organization_id) {
				const { response, error } = await viewOrganization(user.value.organization_id);
				if (error.value) throw error.value;
				const res: any = response.value?.data;
				userOrganizationProfile.value = res?.profile as OrgProfile;
			}

			// Get main shipping address
			const { response, error } = await getMainShippingAddressAddressbook({
				userType: user.value?.organization_id ? "organization_member" : "individual_member",
				user_id: user.value.id,
				organization_id: user.value?.organization_id || null,
			});

			if (typeof error.value !== "boolean" && error.value.status !== 404) {
				// @ts-ignore
				throw new Error(err.message);
			}
			user.value.main_shipping_address = response.value?.data || null;

			return { user: user.value, organization: userOrganizationProfile.value };
		}

		// function for user logout
		function userLogout() {
			logout();
			user.value = undefined;
		}

		// fetch user info when there is token in cookie
		// user doesn't need to login again
		async function getUserInfo() {
			const { response } = await userAuth();
			user.value = response.value?.data.user;
			if (user.value) {
				if (user.value?.organization_id) {
					const { response: orgRes } = await viewOrganization(user.value?.organization_id as string);
					const res = orgRes.value?.data;
					userOrganizationProfile.value = res?.profile as OrgProfile;
				}

				// Get main shipping address
				const { response, error } = await getMainShippingAddressAddressbook({
					userType: user.value?.organization_id ? "organization_member" : "individual_member",
					user_id: user.value.id,
					organization_id: user.value?.organization_id || null,
				});

				if (typeof error.value !== "boolean" && error.value.status !== 404) {
					// @ts-ignore
					throw new Error(error.value.message);
				}
				user.value.main_shipping_address = response.value?.data || null;
				return "Success";
			} else {
				return Promise.reject(new Error("Failed"));
			}
		}

		return {
			user,
			isLoggedIn,
			userOrganizationProfile,
			userLogin,
			userLogout,
			getUserInfo,
		};
	},
	{
		persist: {
			storage: piniaPluginPersistedstate.localStorage(),
		},
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
