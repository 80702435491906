declare global {
	interface Window {
		grecaptcha: any;
	}
}

export interface Recaptcha {
	getRecaptchaToken: () => Promise<string>;
}

export default defineNuxtPlugin(() => {
	const { recaptchaSiteKey } = useRuntimeConfig().public;
	if ("grecaptcha" in window) {
		const grecaptcha = window.grecaptcha;
		return {
			provide: {
				recaptcha: {
					getRecaptchaToken: (): Promise<string> =>
						new Promise(resolve => {
							grecaptcha.enterprise.ready(async () => {
								const token = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action: "LOGIN" });
								resolve(token);
							});
						}),
				},
			},
		};
	}
});
