import CountryList from "country-list-with-dial-code-and-flag/dist";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";

export interface Country {
	id: string;
	continent_alpha2: string;
	continent_name: string;
	continent_sub_region: string;
	country_name: string;
	country_name_official: string;
	country_sovereignty: string;
	country_alpha2: string;
	country_alpha3: string;
	country_numeric_code: string;
	flag: string;
	flagSvg: string;
}

export interface CountryOption extends Country {
	title: string;
	value: string;
}

export interface CountryPlugin {
	list: ComputedRef<CountryOption[]>;
	getNameToAlpha2: (name: string) => string | undefined;
	getAlpha2ToName: (alpha2: string) => string | undefined;
}

export default defineNuxtPlugin(app => {
	const list = ref<CountryOption[]>([]);
	const { listCountries } = useABMApi();
	app.hook("app:beforeMount", async () => {
		const { response } = await listCountries({ options: { query: { limit: 0 } } });
		list.value =
			response.value?.data.map((item: Country) => ({
				title: item.country_name,
				value: item.country_alpha2,
				...item,
				flag: CountryList.findOneByCountryCode(item.country_alpha2)?.flag || "",
				flagSvg: CountryFlagSvg?.[item.country_alpha2] || "",
			})) || [];
	});

	const getNameToAlpha2 = (name: string) =>
		list.value.find((item: CountryOption) => item.country_name === name)?.country_alpha2;

	const getAlpha2ToName = (alpha: string) =>
		list.value.find((item: CountryOption) => item.country_alpha2 === alpha)?.country_name;

	return {
		provide: {
			countries: {
				list: computed(() => list.value),
				getNameToAlpha2,
				getAlpha2ToName,
			},
		},
	};
});
