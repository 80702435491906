
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45uslKFVgF4LEBMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/about-us.vue?macro=true";
import { default as checkout0UbSCyIbo4Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/checkout.vue?macro=true";
import { default as _91id_93VvpFrxJeaVMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/[id].vue?macro=true";
import { default as indexdcQcOTsC3sMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/index.vue?macro=true";
import { default as contactxpmgSlJrU1Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/contact.vue?macro=true";
import { default as how_45to_45sell7I3dTVgTjiMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/how-to-sell.vue?macro=true";
import { default as indexLYllS9xIjXMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/index.vue?macro=true";
import { default as my_45cartIPzdGywnQmMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/my-cart.vue?macro=true";
import { default as order_45confirmationstcPkdgRGeMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/order-confirmation.vue?macro=true";
import { default as _91id_93AGsh6snq2wMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/orders/[id].vue?macro=true";
import { default as indexrICHPIDs2XMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/orders/index.vue?macro=true";
import { default as privacy_45policy3bA66ZbR88Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/privacy-policy.vue?macro=true";
import { default as productsJ5QIFENeO6Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/products.vue?macro=true";
import { default as quality_45guidelinesXa5tiqD9f9Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/quality-guidelines.vue?macro=true";
import { default as secured_45payment_45methodsrdc2NjGf1cMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/secured-payment-methods.vue?macro=true";
import { default as shipping_45return_45policiesPcPAT5zXs5Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/shipping-return-policies.vue?macro=true";
import { default as terms_45of_45servicesKe4SwvzrMzMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/terms-of-services.vue?macro=true";
import { default as _91id_931MviiWS6vgMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/textiles/[id].vue?macro=true";
import { default as authu6dfMU8I0IMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/auth.vue?macro=true";
import { default as indexkU4D2DBwwtMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/index.vue?macro=true";
export default [
  {
    name: "org_slug-about-us",
    path: "/:org_slug()/about-us",
    meta: about_45uslKFVgF4LEBMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/about-us.vue")
  },
  {
    name: "org_slug-checkout",
    path: "/:org_slug()/checkout",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/checkout.vue")
  },
  {
    name: "org_slug-collections-id",
    path: "/:org_slug()/collections/:id()",
    meta: _91id_93VvpFrxJeaVMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/[id].vue")
  },
  {
    name: "org_slug-collections",
    path: "/:org_slug()/collections",
    meta: indexdcQcOTsC3sMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/index.vue")
  },
  {
    name: "org_slug-contact",
    path: "/:org_slug()/contact",
    meta: contactxpmgSlJrU1Meta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/contact.vue")
  },
  {
    name: "org_slug-how-to-sell",
    path: "/:org_slug()/how-to-sell",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/how-to-sell.vue")
  },
  {
    name: "org_slug",
    path: "/:org_slug()",
    meta: indexLYllS9xIjXMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/index.vue")
  },
  {
    name: "org_slug-my-cart",
    path: "/:org_slug()/my-cart",
    meta: my_45cartIPzdGywnQmMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/my-cart.vue")
  },
  {
    name: "org_slug-order-confirmation",
    path: "/:org_slug()/order-confirmation",
    meta: order_45confirmationstcPkdgRGeMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/order-confirmation.vue")
  },
  {
    name: "org_slug-orders-id",
    path: "/:org_slug()/orders/:id()",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/orders/[id].vue")
  },
  {
    name: "org_slug-orders",
    path: "/:org_slug()/orders",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/orders/index.vue")
  },
  {
    name: "org_slug-privacy-policy",
    path: "/:org_slug()/privacy-policy",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/privacy-policy.vue")
  },
  {
    name: "org_slug-products",
    path: "/:org_slug()/products",
    meta: productsJ5QIFENeO6Meta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/products.vue")
  },
  {
    name: "org_slug-quality-guidelines",
    path: "/:org_slug()/quality-guidelines",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/quality-guidelines.vue")
  },
  {
    name: "org_slug-secured-payment-methods",
    path: "/:org_slug()/secured-payment-methods",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/secured-payment-methods.vue")
  },
  {
    name: "org_slug-shipping-return-policies",
    path: "/:org_slug()/shipping-return-policies",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/shipping-return-policies.vue")
  },
  {
    name: "org_slug-terms-of-services",
    path: "/:org_slug()/terms-of-services",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/terms-of-services.vue")
  },
  {
    name: "org_slug-textiles-id",
    path: "/:org_slug()/textiles/:id()",
    meta: _91id_931MviiWS6vgMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/textiles/[id].vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authu6dfMU8I0IMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/auth.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexkU4D2DBwwtMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/index.vue")
  }
]