import type { User } from "@/types/user";

const COOKIE_OPTIONS = {
	maxAge: 86400 * 30,
	sameSite: true,
	secure: true,
};

const isTokenExpiredIn5Mins = (token: string) => {
	const jwtObject = parseJWT(token);

	const expInMilliseconds = jwtObject.exp * 1000;

	const dateExpireMinus5Minutes = new Date(expInMilliseconds);

	dateExpireMinus5Minutes.setMinutes(dateExpireMinus5Minutes.getMinutes() - 5);

	return dateExpireMinus5Minutes < new Date();
};

export type NormalLoginForm = {
	email: string;
	password: string;
	rememberMe: boolean;
	platform_id: string | null;
};

export type SSOLoginForm = {
	sso_partner: string;
	sso_credential: string;
	platform_id: string | null;
};

export type LoginForm = NormalLoginForm | SSOLoginForm;

export const useURM = () => {
	const { $recaptcha } = useNuxtApp();
	const { loginAuth, userAuth, tokenAuth } = useURMApi();

	const login = async (formData: LoginForm, sso = false): Promise<User> => {
		const recaptchaToken = await $recaptcha.getRecaptchaToken();
		const requestBody = sso
			? {
					...formData,
				}
			: {
					...formData,
					recaptchaToken,
				};

		const { response, error } = await loginAuth(requestBody);

		if (error.value) throw error.value;
		else {
			const userEmailCookie = useCookie<string>("user_email", {
				...COOKIE_OPTIONS,
			});
			const jwtTokenCookie = useCookie<string>("jwt_token", {
				...COOKIE_OPTIONS,
				sameSite: "lax",
			});
			if (!sso && (<NormalLoginForm>formData).rememberMe) userEmailCookie.value = (<NormalLoginForm>formData).email;
			jwtTokenCookie.value = response.value.data.token;
			return response.value.data.user;
		}
	};

	const refreshUser = async (): Promise<User | null> => {
		const jwtToken = useCookie<string>("jwt_token");
		const canRefreshUser = !!jwtToken.value;

		if (!canRefreshUser) return null;
		else {
			// Refresh token if it is close to be expired.
			if (jwtToken.value && isTokenExpiredIn5Mins(jwtToken.value)) {
				const { response, error } = await tokenAuth<string>();
				if (error.value) throw error.value;
				jwtToken.value = response.value.data;
			}

			// Finally, Refresh user with valid jwt token.
			const { response, error } = await userAuth();
			if (error.value) throw error.value;
			return response.value.data.user;
		}
	};

	const logout = () => {
		const userEmailCookie = useCookie<boolean | null>("user_email");
		const jwtTokenCookie = useCookie<string | null>("jwt_token");
		userEmailCookie.value = null;
		jwtTokenCookie.value = null;
	};

	return {
		login,
		logout,
		refreshUser,
	};
};
